


















































import Vue from "vue";

export default Vue.extend({
  name: "end-of-step",
  props: {
    documents: Array,
    showDocuments: Boolean,
  },
  data() {
    return {
      social: [
        {
          icon: "mdi-facebook",
          url: "#",
        },
        {
          icon: "mdi-instagram",
          url: "#",
        },
        {
          icon: "mdi-twitter",
          url: "#",
        },
      ],
    };
  },
});
